var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wontchange"},[_c('div',{staticClass:"section5"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy"}],staticClass:"bg",class:{ inactive: _vm.active.hasOwnProperty('h') },attrs:{"temp":_vm.isMobile
          ? require('@/projects/cc/s5/bg-mo.png')
          : require('@/projects/cc/s5/bg.png'),"alt":"","srcset":"","uk-parallax":"viewport:0.3;blur:50,0"}}),_vm._l((_vm.list),function(item,i){return [(!_vm.isMobile)?_c('div',{key:i,staticClass:"item",class:{
          active: _vm.active == item,
          inactive: _vm.active != item && _vm.active.hasOwnProperty('h'),
          last: i == _vm.list.length - 1,
        },attrs:{"uk-parallax":("viewport:0.3;blur:10,0;y:" + (i * 200) + ",0")},on:{"mouseenter":function($event){_vm.active = item},"mouseleave":function($event){_vm.active = {}}}},[_c('img',{attrs:{"src":item.t,"alt":"","srcset":""}})]):_c('div',{key:i,staticClass:"item",class:{
          active: _vm.active == item,
          inactive: _vm.active != item && _vm.active.hasOwnProperty('h'),
          last: i == _vm.list.length - 1,
        },on:{"click":function($event){_vm.active = item}}},[_c('img',{attrs:{"src":item.t,"alt":"","srcset":""}})])]}),(!_vm.isMobile)?_c('div',{staticClass:"hidden",class:{ active: _vm.active.hasOwnProperty('h') },style:({
        backgroundImage: ("url(" + (_vm.active.h) + ")"),
      })}):_vm._e()],2),(_vm.isMobile)?_c('div',{staticClass:"hidden",class:{ active: _vm.active.hasOwnProperty('h') },style:({
      backgroundImage: ("url(" + (_vm.active.h) + ")"),
    }),on:{"click":function($event){_vm.active = {}}}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }