export default [{
        name: "國際CBD",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section2-t",
        svgSrc: "",
        offset:-100,
        mobileOffset:-100
    },
    {
        name: "新名邸特區",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section4",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "生活新風貌",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section6",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    {
        name: "校園第一排",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section8",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
    /*
    {
        name: "人文青建築",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "section9",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    }

    */
];